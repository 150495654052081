import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import statesList from '../Helpers/StatesList';
import ReactLoading from 'react-loading';

import { Select } from 'antd';

import { SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';

import NumberFormat from 'react-number-format';

import { getInsuranceCompanies, getPatientInformationByIDNew } from '../../common/services/ApiService';

import { toast } from '@rickylandino/react-messages';

import MaskedInput from 'react-text-mask'
import { getProvidersByFacility } from '../../common/services/FacilityService';

class AddPatientPane extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            if (nextProps.showPane === true) {
                return {
                    showPane: nextProps.showPane
                };
            }
            else {
                return {
                    showPane: nextProps.showPane
                }
            }

        }
        return null;
    }

    constructor(props) {
        super(props);

        let formFields = {};
        if (Globals.associatedFacilities?.length === 1) {
            formFields.facilityID = Globals.associatedFacilities[0].facilityModel.facilityID
        }

        this.state = {
            showPane: false,
            formFields,
            states: statesList.filter(obj => { return obj.country_code === 'US' }),
            priorStatusList: [],
            insuranceCompanies: [],
            providersList: [],
            primaryCarePhysicians: [],
            selectedEthnicity: [],
            loadingAdd: false
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.allowModal = true;
        this.popEventListnerAdded = false;

    }

    componentDidMount() {
        let props = this;
        if (!this.popEventListnerAdded) {
            $(window).on("popstate", function (event) {
                props.popEventListnerAdded = true;
                event.preventDefault();
                event.stopPropagation();

                if (props.isDirty && props.allowModal && Globals.isDirtyPage) {
                    SmartMessageBox(
                        {
                            title: "You are about to lose all changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: "[No][Yes]"
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                //window.history.back();
                                props.hidePane();
                                props.allowModal = false;
                                props.isDirty = false;
                                Globals.isDirtyPage = false;
                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                window.history.pushState(null, null, null);
                                return 0;
                            }
                        }
                    );
                }
            });
        }

        this.getAllPriorStatus();
        this.getInsuranceCompanies();
        this.getProvidersByFac(this.state.formFields.facilityID);
        this.getAllPrimaryCarePhysicians();
    }

    componentDidUpdate() {
        if (this.checkOnDirty && this.isDirty) {
            this.checkOnDirty = false;
            window.history.pushState(null, null, null);
        }
    }

    hidePane = () => {
        this.setState({
            formFields: {},
            showPane: false
        });

        this.props.hidePane();
    }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let providersList = [...this.state.providersList];


        if (name === 'facilityID') {
            providersList = [];
            const pList = await getProvidersByFacility(value);

            for (var i = 0; i < pList.length; i++) {
                pList[i].name = pList[i].fName + ' ' + pList[i].lName
            }

            providersList = pList;
        }

        this.setState({
            formFields: { ...this.state.formFields, [name]: value },
            providersList
        });

        
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    getAllPriorStatus = () => {
        Axios.get(`/api/GetAllPriorStatus`
        ).then(response => {
            this.setState({
                priorStatusList: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getInsuranceCompanies = () => {
        getInsuranceCompanies().then(response => this.setState({ insuranceCompanies: response }));
    }

    getProvidersByFac = async (value) => {
        let providersList = [];
        const pList = await getProvidersByFacility(value);

        for (var i = 0; i < pList.length; i++) {
            pList[i].name = pList[i].fName + ' ' + pList[i].lName
        }

        providersList = pList;

        this.setState({
            providersList
        });
    }

    getProviders = () => {
        Axios.get(`/api/GetAllProviders`
        ).then(response => {
            let providersList = response.data;
            for (var i = 0; i < providersList.length; i++) {
                providersList[i].name = providersList[i].fName + ' ' + providersList[i].lName
            }

            this.setState({
                providersList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getAllPrimaryCarePhysicians = () => {
        Axios.get(`/api/GetAllPrimaryCarePhysicians`
        ).then(response => {
            this.setState({
                primaryCarePhysicians: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    selectPatient = (patient) => {
        if (patient) {
            let postdata = {
                uniqueID: patient.patientModel.patientID
            };

            Axios.get(`/api/GetAllPatientExamInfoByPatient`, {
                params: {
                    patientID: patient.patientModel.patientID
                }
            }).then(response => {
                this.props.history.push({
                    pathname: '/patient-dashboard',
                    state: { selectedObject: patient, examInfo: response.data }
                });

            }).catch(error => {
                console.log(error);
            });
        }

    }

    handleSubmit = () => {
        this.setState({
            loadingAdd: true
        });
        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let formFields = this.state.formFields;

        if (Globals.associatedFacilities?.length === 1) {
            formFields.facilityID = Globals.associatedFacilities[0].facilityModel.facilityID;
        }

        let patient = this.state.formFields;

        let postdata = {
            patient,
            ethnicBackground: this.state.selectedEthnicity,
            userID: userInfo.userId
        };

        if (!patient.lName || !patient.fName || !patient.mrn || !patient.dob ||
            !patient.facilityID || !patient.address1 || !patient.city || !patient.state || !patient.zip || !patient.providerID || !patient.insuranceID || !this.state.selectedEthnicity?.length > 0) {
            toast.error("Please fill in all required data");
        } else {
            Axios.post(`/api/InsertPatient`, postdata
            ).then(response => {
                if (response.data) {
                    toast.success("Patient has been added");

                    getPatientInformationByIDNew(response.data).then(data => {
                        this.props.history.push({
                            pathname: '/patient-dashboard',
                            state: { selectedObject: data.patientInformation, examInfo: data.examInfo, filteredCategoryList: data.categoryList, patientEthnicity: data.patientEthnicity }
                        });
                    });
                } else {
                    toast.error("Error - A duplicate MRN cannot be entered");
                    this.setState({
                        loadingAdd: false
                    });
                }


            }).catch(error => {
                console.log(error);
                this.setState({
                    loadingAdd: false
                });
            });
        }
    }

    handleEthnicBackgroundChange = (selectedEthnicity) => {

        this.setState({
            selectedEthnicity
        });
    }
    

    render() {
        const { Option } = Select;

        const theme = window.sessionStorage.getItem("theme");

        var filteredInsurance = [];
        var filteredPcps = [];

        if (this.state.formFields.facilityID) {
            filteredInsurance = this.state.insuranceCompanies?.filter(i => i.facilityID === this.state.formFields.facilityID);
            filteredPcps = this.state.primaryCarePhysicians?.filter(pcp => pcp.facilityID === this.state.formFields.facilityID);
        }

        return (
            <SlidingPane
                overlayClassName='pinkCard'
                className={theme === 'dark' ? 'dark-theme w-100' : "light-theme w-100"}
                isOpen={this.state.showPane}
                title='Add Patient'
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body">
                    <div className="row justify-content-end">
                        <div className="form-group col-12 text-right">
                           <span className="ml-3 fs-24 color-pink">*</span> = Required Field
                        </div>
                    </div>
                    <Card className="pinkCard">
                        <Card.Header><span>General Information</span>
                        </Card.Header>
                        <Card.Body>

                            <form>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Last Name <span className="color-pink">*</span></label>
                                        <input type="text" name="lName" value={this.state.formFields.lName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">First Name <span className="color-pink">*</span></label>
                                        <input type="text" name="fName" value={this.state.formFields.fName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label not-required">Middle Name</label>
                                        <input type="text" name="mName" value={this.state.formFields.mName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">MRN <span className="color-pink">*</span></label>
                                        <input type="text" name="mrn" value={this.state.formFields.mrn || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">DOB <span className="color-pink">*</span></label>
                                        <DatePicker
                                            selected={this.state.formFields.dob ? Moment(this.state.formFields.dob).utc().toDate() : null}
                                            onChange={(date) => this.handleDateChange('dob', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            }
                                        />
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Facility <span className="color-pink">*</span></label>
                                        {Globals.associatedFacilities?.length === 1 ? 
                                            <input type="text" value={Globals.associatedFacilities[0].facilityModel.facilityName} className="form-control-custom no-border" disabled/>
                                            :
                                            <select className="form-control-custom" value={this.state.formFields.facilityID} name="facilityID" onChange={this.handleInputChange}>
                                                <option></option>
                                                {Globals.associatedFacilities.filter(item => item.facilityModel.inactiveYN !== 1).map((fac, idx) => <option key={idx} value={fac.facilityModel.facilityID} onChange={this.handleInputChange}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</option>)}

                                            </select>
                                        }
                                        
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Address 1 <span className="color-pink">*</span></label>
                                        <input type="text" name="address1" value={this.state.formFields.address1 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label className="form-label not-required">Address 2</label>
                                        <input type="text" name="address2" value={this.state.formFields.address2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">City <span className="color-pink">*</span></label>
                                        <input type="text" name="city" value={this.state.formFields.city || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-3">
                                        <label className="form-label">State <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.state} name="state" onChange={this.handleInputChange}>
                                            <option></option>
                                            {this.state.states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                                        </select>
                                    </div>

                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Zip <span className="color-pink">*</span></label>
                                        <input type="text" name="zip" value={this.state.formFields.zip || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label not-required">Cell Phone</label>
                                        <NumberFormat className="form-control-custom" name="cellPhone" value={this.state.formFields.cellPhone || ''} onChange={this.handleInputChange} format="(###) ###-####" mask="_" />
                                    </div>

                                    <div className="col-lg-3 col-12">
                                        <label className="form-label not-required">Patient consents to receive text alerts</label>
                                        <div className="form-control-custom no-border">
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" id="" name="consentToTexts" onChange={this.handleInputChange} defaultChecked={this.state.formFields.consentToTexts} />
                                                <label className="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label not-required">Home Phone</label>
                                        <NumberFormat className="form-control-custom" name="homePhone" value={this.state.formFields.homePhone || ''} onChange={this.handleInputChange} format="(###) ###-####" mask="_" />
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label not-required">Work Phone</label>
                                        <NumberFormat className="form-control-custom" name="workPhone" value={this.state.formFields.workPhone || ''} onChange={this.handleInputChange} format="(###) ###-####" mask="_" />
                                    </div>

                                    <div className="form-group col-lg-1">
                                        <label className="form-label not-required">Ext.</label>
                                        <input type="number" name="workPhoneExt" value={this.state.formFields.workPhoneExt || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label not-required">Email</label>
                                        <input type="text" name="email" value={this.state.formFields.email || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Insurance <span className="color-pink">*</span></label>
                                        {this.state.formFields.facilityID ? 
                                            <select className="form-control-custom" value={this.state.formFields.insuranceID} name="insuranceID" onChange={this.handleInputChange}>
                                                <option></option>
                                                {filteredInsurance.map((company, idx) => <option key={idx} value={company.insuranceID} onChange={this.handleInputChange}>{company.insCompName}</option>)}

                                            </select>
                                            :
                                            <div className="form-control-custom no-border">Please select a facility first</div>
                                        }
                                        
                                    </div>
                                    
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Referring MD <span className="color-pink">*</span></label>
                                        {this.state.formFields.facilityID ?
                                            <select className="form-control-custom" value={this.state.formFields.providerID} name="providerID" onChange={this.handleInputChange}>
                                                <option />
                                                {this.state.providersList.map((prov, idx) => <option key={idx} value={prov.providerID} onChange={this.handleInputChange}>{prov.name}</option>)}

                                            </select>
                                            :
                                            <div className="form-control-custom no-border">Please select a facility first</div>
                                        }
                                        
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label not-required">Primary Care Physician</label>
                                        {this.state.formFields.facilityID ?
                                            <select className="form-control-custom" value={this.state.formFields.primaryCarePhysicianID} name="primaryCarePhysicianID" onChange={this.handleInputChange}>
                                                <option />
                                                {filteredPcps.map((pcp, idx) => <option key={idx} value={pcp.primaryCarePhysicianID}>{pcp.fName} {pcp.lName}</option>)}
                                            </select>
                                            :
                                            <div className="form-control-custom no-border">Please select a facility first</div>
                                        }
                                        
                                    </div>


                                    <div className="form-group col-lg-2">
                                        <label className="form-label not-required">Next Appt Date</label>
                                        <DatePicker
                                            selected={this.state.formFields.nextApptDate ? Moment(this.state.formFields.nextApptDate).toDate() : null}
                                            onChange={(date) => this.handleDateChange('nextApptDate', date)}
                                            className="form-control-custom"
                                            showTimeSelect
                                            dateFormat="MM/dd/yyyy hh:mm aa"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <MaskedInput
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/, ' ', /(?:A|P|a|p)/, /(?:M|m)/]}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label not-required">Prior Status</label>
                                        <select className="form-control-custom" value={this.state.formFields.priorStatusID} name="priorStatusID" onChange={this.handleInputChange}>
                                            <option value={null}></option>
                                            {this.state.priorStatusList.map((status, idx) => <option key={idx} value={status.priorStatusID}>{status.priorStatus}</option>)}
                                        </select>
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Racial or Ethnic Background <span className="color-pink">*</span></label>
                                        <Select
                                            mode="multiple"
                                            maxTagCount={1}
                                            allowClear
                                            placeholder="Please select"
                                            onChange={this.handleEthnicBackgroundChange}
                                            className="form-control-custom w-100"
                                            bordered={false}
                                            value={this.state.selectedEthnicity}
                                        >
                                            <Option value='AfricanAmericanBlack'>African American/Black</Option>
                                            <Option value='CaucausianWhite'>Caucasian/White</Option>
                                            <Option value='HispanicLatina'>Hispanic/Latina</Option>
                                            <Option value='AmericanIndianAlaskaNative'>American Indian/Alaska Native</Option>
                                            <Option value='Asian'>Asian</Option>
                                            <Option value='NativeHawaiianOtherPacificIslander'>Native Hawaiian/Other Pacific Islander</Option>
                                            <Option value='OtherRace'>Other</Option>
                                        </Select>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label not-required">Clinical</label>
                                        <textarea rows="6" name="clinicalNotes" value={this.state.formFields.clinicalNotes || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label not-required">Notes</label>
                                        <textarea rows="6" name="notes" value={this.state.formFields.notes || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                    </div>
                                </div>
                            </form>

                        </Card.Body>
                    </Card>

                </div>

                <div className="modal-footer">
                    {this.state.loadingAdd ?
                        <div className="form-group col-12">
                            <ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} />
                        </div>
                        :
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.handleSubmit}>Add Patient</button>
                            <button className="btn btn-outline-default margin-left-15" onClick={this.hidePane}>Cancel</button>
                        </div>
                    }
                    
                </div>
            </SlidingPane>
        );
    }
}

export default withRouter(AddPatientPane);