import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../css/table.css';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

import NumberFormat from 'react-number-format';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import ReactLoading from 'react-loading';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { getPatientInformationByIDNew } from '../../common/services/ApiService';

import { toast, confirm } from '@rickylandino/react-messages';
import { Select } from 'antd';
import { getLoadInfo } from '../../common/services/UsersService';

class WorklistHome extends Component {
    _isMounted = false;

    static getDerivedStateFromProps(nextProps, prevState) {

        if (Globals.selectedFacility.facilityID && (nextProps.selectedFacility.facilityID) && (nextProps.selectedFacility !== prevState.selectedFacility)) {
            return {
                selectedFacility: nextProps.selectedFacility,
                facilitySelected: true
            }
        }

        return null;
    }

    constructor(props) {
        super(props);

        var nowDate = new Date();
        this.mwlDate = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
        
        this.state = {
            formFields: {
                searchField: '',
                modality: '',
                studyDate: new Date(this.mwlDate)
            },
            patients: [],
            dicomPatients: [],
            filteredList: [],
            selectedFacility: {},
            facilitySelected: false,
            showPane: false,
            loadingPatients: false,
            selectedPatient: {},
            providersList: [],
            expanded: [],
            selectedDicomPatient: {},
            selectedProvider: {},
            selectedDates: {
                startDate: new Date(),
                endDate: new Date()
            },
            selectedCategories: [],
            insuranceList: [],
            selectedFacilityID: '',
            userAssociatedFacilities: Globals.associatedFacilities
        }

    }

    componentDidMount() {
        this._isMounted = true;

        let defaultFacility = JSON.parse(window.sessionStorage.getItem("defaultFacility"));

        if (this.state.userAssociatedFacilities?.length === 0) {
            getLoadInfo(Globals.userInfo.userId).then(data => {
                Globals.associatedFacilities = data.userAssociatedFacilities;
                this.setState({
                    userAssociatedFacilities: data.userAssociatedFacilities
                });
            });
        }

        if (defaultFacility.facilityID) {
            let formFields = this.state.formFields;
            formFields.facilityID = defaultFacility.facilityID;

            let selectedFacilityID = defaultFacility.facilityID;

            this.setState({
                selectedFacility: defaultFacility,
                selectedFacilityID,
                facilitySelected: true,
                loading: true,
                formFields
            }, () => { this.getAllDicomPatientsByFacility(); this.getProvidersByFacility(); this.getInsuranceByFacility(); });
        } else {
            this.getAllDicomPatientsByFacility();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this._isMounted) {
            if (Globals.selectedFacility.facilityID && (this.props.selectedFacility.facilityID) && (this.props.selectedFacility.facilityID !== prevState.selectedFacility.facilityID)) {
                this.setState({
                    loading: true
                });
                this.getAllDicomPatientsByFacility();
            }
        }
        
    }

    getAllDicomPatientsByFacility = () => {
        let postdata = {
            uniqueID: this.state.selectedFacility.facilityID
        }

        Axios.post(`/api/GetAllDicomPatientsByFacility`, postdata
        ).then(response => {
            this.setState({
                dicomPatients: response.data
            }, () => this.filterByDateRange());

        }).catch(error => {
            console.log(error);
        });
    }

    getProvidersByFacility = () => {
        Axios.get(`/api/GetProvidersByFacility`, {
            params: {
                facilityID: this.state.selectedFacility.facilityID
            } 
        }).then(response => {
            let providersList = response.data;
            this.setState({
                providersList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getInsuranceByFacility = () => {
        Axios.get(`/api/GetInsuranceCompaniesByFacility`, {
            params: {
                facilityID: this.state.selectedFacility.facilityID
            } 
        }).then(response => {
            let insuranceList = response.data;
            this.setState({
                insuranceList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'providerID') {
            var selectedProvider = this.state.providersList.find(prov => prov.providerID === value);
            this.setState({ selectedProvider });
        }

        if (name === 'facilityID') {
            this.setState({
                facilitySelected: true
            });

            let selectedFacility = this.state.userAssociatedFacilities.find(fac => fac.facilityModel.facilityID === value).facilityModel;
            this.setState({
                selectedFacility,
                facilitySelected: true,
                loading: true
            }, () => { this.getAllDicomPatientsByFacility(); this.getProvidersByFacility(); this.getInsuranceByFacility(); });

            
        }

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });

    }

    handleFacilityChange = (selectedFacilityID) => {

        let selectedFacility = this.state.userAssociatedFacilities.find(fac => fac.facilityModel.facilityID === selectedFacilityID).facilityModel;
        this.setState({
            facilitySelected: true,
            selectedFacility,
            facilitySelected: true,
            loading: true,
            selectedFacilityID,
            formFields: { ...this.state.formFields, facilityID: selectedFacilityID }
        }, () => {
                this.getAllDicomPatientsByFacility();
                this.getProvidersByFacility();
                this.getInsuranceByFacility();
        });
    }

    handleTableDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleSbmt = (event) => {
        event.preventDefault();
        let postdata = {
            uniqueID: this.state.selectedFacility && this.state.selectedFacility.facilityID,
            name: this.state.formFields.searchField
        }

        this.setState({
            showPane: true,
            loadingPatients: true
        });

        Axios.post(`/api/GetPatientsByNameOrMRN`, postdata
        ).then(response => {
            this.setState({
                patients: response.data,
                loadingPatients: false,
                selectedPatient: response.data[0]
            })

        }).catch(error => {
            console.log(error);
        });

    }

    addToWorklist = () => {
        let patient = this.state.selectedPatient.patientModel;

        let modalitySplit = this.state.formFields.modality.split(" ");

        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let dicom_PatientModel = {
            patientID: patient.mrn,
            patientGUID: patient.patientID,
            patientsFirstName: patient.fName,
            patientsLastName: patient.lName,
            patientsBirthDate: new Date(patient.dob),
            //patientsAge: '',
            patientsSex: 'F',
            serverPartitionGUID: patient.facilityID,
            responsibleOrganization: this.state.selectedPatient.facilityModel.facilityName,
            referringPhysiciansFirstName: this.state.selectedPatient.providerModel && this.state.selectedPatient.providerModel.fName,
            referringPhysiciansLastName: this.state.selectedPatient.providerModel && this.state.selectedPatient.providerModel.lName,
            npi: this.state.selectedPatient?.providerModel?.npi || null,
            sentToModalityYN: '1',
            mirth: '0',
            modality: modalitySplit[1] === 'Mammogram' ? 'MG' : 'US',
            studyDate: this.state.formFields.studyDate,
            accessionNo: null,
            status: 'SC',
            examClass: modalitySplit[0],
            userID: userInfo.userId
        }

        Axios.post(`/api/InsertDicomPatient`, dicom_PatientModel
        ).then(response => {

            //simulates body click to hide popover
            document.body.click();

            if (response.data === 'Patient has an incomplete exam on file') {
                toast.error(response.data)
            } else {
                toast.success(response.data);

                this.setState({
                    formFields: {},
                    showPane: false
                });

                Globals.selectedFacility = this.state.selectedPatient.facilityModel;
                this.getAllDicomPatientsByFacility();
            }

            

        }).catch(error => {
            console.log(error);
        });
    }

    updateDicomPatient = () => {
        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let dicom_PatientModel = this.state.formFields;
        dicom_PatientModel.referringPhysiciansFirstName = this.state.selectedProvider ? this.state.selectedProvider.fName : null;
        dicom_PatientModel.referringPhysiciansLastName = this.state.selectedProvider ? this.state.selectedProvider.lName : null;
        dicom_PatientModel.npi = this.state.selectedProvider?.npi || null;
        dicom_PatientModel.referringPhysiciansID = this.state.selectedProvider ? this.state.selectedProvider.providerID : null;
        dicom_PatientModel.userID = userInfo.userId;

        Axios.post(`/api/UpdateDicomPatient`, dicom_PatientModel
        ).then(response => {
            if (response.data) {

                toast.success("Patient has been updated.");

                this.setState({
                    formFields: {
                        modality: '',
                        studyDate: new Date(this.mwlDate)
                    },
                    expanded: []
                })

                this.getAllDicomPatientsByFacility();
            }
            else {

                toast.error("MRN is not unique. Please try with a unique MRN.");
            }

        }).catch(error => {

            toast.error("Something went wrong. Please log out and try again.");
        });
    }

    deleteDicomPatient = () => {

        confirm({
            title: "You are about to delete this patient from the MWL.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
                let dicom_PatientModel = this.state.formFields;

                dicom_PatientModel.userID = userInfo.userId;

                Axios.post(`/api/DeleteDicomPatient`, dicom_PatientModel
                ).then(response => {

                    toast.success("Patient has been deleted.");

                    this.setState({
                        formFields: {
                            modality: '',
                            studyDate: new Date(this.mwlDate)
                        },
                        expanded: []
                    })

                    this.getAllDicomPatientsByFacility();
                }).catch(error => {
                    toast.error("Something went wrong. Please log out and try again.");
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (e.target.id !== 'deleteIcon' && e.target.id !== 'openChart') {
            let expanded = [];
            expanded.push(row.serNo);
            this.setState(() => ({
                expanded
            }));
        } else {
            this.setState({
                expanded: []
            });
        }
        
    }

    handleBtnClick = () => {

        this.setState(() => ({
            expanded: this.state.expanded.filter(x => x !== 2)
        }));

    }

    selectPatient = (patient) => {
        if (patient) {
            getPatientInformationByIDNew(patient.patientModel.patientID).then(data => {
                this.setState({
                    selectedPatient: data.patientInformation
                });

                this.props.history.push({
                    pathname: '/patient-dashboard',
                    state: { selectedObject: data.patientInformation, examInfo: data.examInfo }
                });
            });

            //let postdata = {
            //    uniqueID: patient.patientModel.patientID
            //};

            //Axios.post(`/api/GetAllPatientExamInfoByPatient`, postdata
            //).then(response => {
            //    this.props.history.push({
            //        pathname: '/patient-dashboard',
            //        state: { selectedObject: patient, examInfo: response.data }
            //    });

            //}).catch(error => {
            //    console.log(error);
            //});
        }

    }

    handleNewDateChange = (name, date) => {
        this.setState({ selectedDates: { ...this.state.selectedDates, [name]: date } });
    }

    filterByDateRange = () => {

        let filteredList = [];
        if (this.state.selectedDates.startDate && this.state.selectedDates.endDate) {
            if (Moment(this.state.selectedDates.startDate).isSame(Moment(this.state.selectedDates.endDate), 'day')) {
                filteredList = this.state.dicomPatients.filter(dp => Moment(dp.studyDate).isSame(Moment(this.state.selectedDates.startDate), 'day'));
            } else {
                filteredList = this.state.dicomPatients.filter(dp => (Moment(dp.studyDate).isBetween(Moment(this.state.selectedDates.startDate), Moment(this.state.selectedDates.endDate), 'day')
                    || Moment(dp.studyDate).isSame(Moment(this.state.selectedDates.endDate), 'day')
                    || Moment(dp.studyDate).isSame(Moment(this.state.selectedDates.startDate), 'day')));
            } 
        } else if (this.state.selectedDates.startDate) {
            filteredList = this.state.dicomPatients.filter(dp => Moment(dp.studyDate).isSameOrAfter(Moment(this.state.selectedDates.startDate), 'day'));
        } else if (this.state.selectedDates.endDate) {
            filteredList = this.state.dicomPatients.filter(dp => Moment(dp.studyDate).isSameOrBefore(Moment(this.state.selectedDates.endDate), 'day'));
        } else {
            filteredList = this.state.dicomPatients
        }

        let filterWithModality = [];

        /*
         if (cell === 'MG') {
                modalityDisplay = 'Mammogram';
            } else if (cell === 'US') {
                modalityDisplay = 'Ultrasound';
            } else {
                modalityDisplay = cell;
            }
            return (
                <span>{row.examClass} {modalityDisplay}</span>
            );
         */

        let expandedCats = this.state.selectedCategories.map(sc => {
            let retval = {};

            switch (sc) {
                case 'Screening Mammo':
                    retval = {
                        examClass: 'Screening',
                        modality: 'MG'
                    };
                    break;
                case 'Dx Mammo':
                    retval = {
                        examClass: 'Diagnostic',
                        modality: 'MG'
                    };
                    break;
                case 'US':
                    retval = {
                        examClass: 'Screening',
                        modality: 'US'
                    };
                    break;
                case 'Ultrasound':
                    retval = {
                        examClass: 'Diagnostic',
                        modality: 'US'
                    };
                    break;
                default:
                    break;
            }

            return retval;
        }) || [];

        expandedCats.forEach(c => {
            let fList = filteredList.filter(f => f.modality === c.modality && f.examClass === c.examClass);
            filterWithModality = filterWithModality.concat(fList);
        });

        if (filterWithModality.length === 0 && this.state.selectedCategories.length === 0) {
            filterWithModality = filteredList;
        }

        this.setState({
            filteredList: [...filterWithModality]
        });
    }

    handleMwlDateChange = (date) => {
        if (date) {
            let mwlTimeFormat = "MMMM d, yyyy h:mm aa";
            if (date.toString().includes('00:00:00'))
                mwlTimeFormat = "MMMM d, yyyy";

            this.setState({ formFields: { ...this.state.formFields, studyDate: date }, mwlTimeFormat });
        }
        
    }

    render() {
        const { Option } = Select;

        const handleMwlDateChange = (date) => {
            this.handleMwlDateChange(date);
        }

        const handleInputChange = (e) => {
            this.handleInputChange(e);
        }

        const addToWorklist = () => {
            this.addToWorklist();
        }

        const emptyFormFields = () => {
            let formFields = {
                modality: '',
                studyDate: new Date(this.mwlDate)
            };
            this.setState({
                formFields
            });
        }

        const mwlFormatter = (cell, row, index, formatExtraData) => {
            let disableBtn = formatExtraData.st.formFields.modality === '' ? true : false;
            let studyDate = formatExtraData.st.formFields.studyDate;
            let mwlTimeFormat = formatExtraData.st.mwlTimeFormat;

            var modalityList = [];

            row.categoryList.forEach(item => {
                switch (item.category) {
                    case 'SCRMAM':
                        modalityList.push("Screening Mammogram");
                        break;
                    case 'DXMAM':
                        modalityList.push("Diagnostic Mammogram");
                        break;
                    case 'SCRUS':
                        modalityList.push("Screening Ultrasound");
                        break;
                    case 'DXUS':
                        modalityList.push("Diagnostic Ultrasound");
                        break;
                    default:
                        break;
                }
            });

            if (modalityList.length === 0) return;

            return (
                <OverlayTrigger
                    trigger={"click"}
                    rootClose
                    key='top'
                    placement='bottom'
                    onExit={emptyFormFields}
                    overlay={
                        <Popover id='popover' className="mwlPopover">
                            <Popover.Title as="h3">Select a Modality</Popover.Title>
                            <Popover.Content>
                                <div>
                                    {modalityList.map((mod, idx) => (
                                        <div className="custom-control custom-radio" key={idx}>
                                            <input type="radio" name="modality" className="custom-control-input" value={mod} onChange={handleInputChange} />
                                            <span className="custom-control-label">{mod}</span>
                                        </div>
                                    ))}
                                </div>

                                <hr />

                                <div>
                                    <div className="form-label">Study Date</div>
                                    <DatePicker
                                        showTimeSelect
                                        dateFormat={mwlTimeFormat}
                                        className="form-control-custom"
                                        onChange={(date) => handleMwlDateChange(date)}
                                        selected={studyDate}
                                        minTime={new Date().setHours(6, 0, 0, 0)}
                                        maxTime={new Date().setHours(21, 0, 0, 0)}
                                        dateFormat="MM/dd/yyyy hh:mm aa"
                                        timeIntervals={15}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>
                            </Popover.Content>
                            <div className="form-group col-12">
                                {disableBtn ?
                                    <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="Select a modality">
                                        <button className="btn btn-light" disabled>Add to MWL</button>
                                    </span>
                                    :
                                    <button className="btn btn-submit" onClick={addToWorklist}>Add to MWL</button>
                                }

                                <button className="btn btn-outline-default margin-left-15" onClick={() => { document.body.click() }}>Cancel</button>
                            </div>
                        </Popover>
                    }
                >
                    <button type="button" id="addToMWL" className="unstyleBtn ml-3" data-container="body" data-toggle="popover" data-placement="top">
                        <i className="fas fa-share-alt fa-125x color-pink text-center" id="addToMWL"></i>
                    </button>

                </OverlayTrigger>

            );
        }


        function fullNameFormatter(cell, row) {
            return (
                <span>{row.referringPhysiciansFirstName ? (row.referringPhysiciansFirstName + ' ' + row.referringPhysiciansLastName) : ''}</span>
            );
        }

        function openChartFormatter(cell, row) {
            return (
                <i className="fas fa-folder-open fa-125x color-pink text-center hover" id="openChart"></i>
            );
        }

        function editFormatter(cell, row) {
            return (
                <i className="fas fa-edit fa-125x color-pink text-center hover" id="editIcon"></i>
            );
        }

        function deleteFormatter(cell, row) {
            return (
                <i className="fas fa-times fa-125x text-danger text-center hover" id="deleteIcon"></i>
            );
        }

        function categoryFormatter(cell, row) {
            var modalityDisplay = '';

            if (cell === 'MG') {
                modalityDisplay = 'Mammogram';
            } else if (cell === 'US') {
                modalityDisplay = 'Ultrasound';
            } else {
                modalityDisplay = cell;
            }
            return (
                <span>{row.examClass} {modalityDisplay}</span>  
            );
        }

        const columns = [{
            dataField: 'studyDate',
            text: 'Exam Date',
            formatter: cell =>
            Moment(new Date(cell)).format('L'),
            sort: true
        }, {
            dataField: 'recordTime',
            isDummyField: true,
            text: 'Exam Time',
            formatter: (cell, row) =>
                Moment(new Date(row.studyDate)).format('LT'),
            sort: true,
            sortValue: (cell, row) =>
                Moment(new Date(row.studyDate)).format('LT'),
        }, {
            dataField: 'patientsLastName',
            text: 'Last Name',
            sort: true
        }, {
            dataField: 'patientsFirstName',
            text: 'First Name',
            sort: true
        }, {
            dataField: 'patientID',
            text: 'Patient ID',
            sort: true
        }, {
            dataField: 'patientsBirthDate',
            text: 'DOB',
            formatter: cell => Moment(new Date(cell)).format('L'),
            sort: true,
            sortValue: cell => Moment(new Date(cell)).format('L'),
        }, {
            dataField: 'insurance',
            text: 'Insurance',
            sort: true
        }, {
            dataField: 'df1',
            text: 'Referring MD',
            formatter: fullNameFormatter,
            sort: true,
            sortValue: (cell, row) => (row.referringPhysiciansFirstName + ' ' + row.referringPhysiciansLastName),
            isDummyField: true
        }, {
            dataField: 'modality',
            text: 'Category',
            sort: true,
            formatter: categoryFormatter
        }, {
            dataField: 'df2',
            isDummyField: true,
            text: 'Edit',
            formatter: editFormatter
        }, {
            dataField: 'df3',
            isDummyField: true,
            text: 'Open Chart',
            formatter: openChartFormatter
        }, {
            dataField: 'df4',
            isDummyField: true,
            text: 'Delete',
            formatter: deleteFormatter
        }];

        const sizePerPageOptionRenderer = ({
            text,
            page,
            onSizePerPageChange
        }) => (
                <li
                    key={text}
                    role="presentation"
                    className="form-control-custom hover"
                    tabIndex="-1"
                    data-page={page}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSizePerPageChange(page);
                    }}
                    style={{ color: 'pink' }}
                >
                    <span
                        tabIndex="-1"
                        role="menuitem"
                        data-page={page}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            onSizePerPageChange(page);
                        }}
                        style={{ color: 'rgba(243, 0, 121, 1)' }}
                    >
                        {text}
                    </span>
                </li>
            );

        const pagination = {
            sizePerPage: 5,
            sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
            sizePerPageOptionRenderer
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                var selectedProvider = this.state.providersList.find(prov => (prov.fName + " " + prov.lName) === row.referringPhysiciansFirstName + " " + row.referringPhysiciansLastName);
                var providerID = selectedProvider ? selectedProvider.providerID : null;
                if (e.target.tagName === 'I' && e.target.id === 'deleteIcon') {
                    let formFields = row;
                    formFields.providerID = providerID;
                    formFields.name = row.referringPhysiciansFirstName + ' ' + row.referringPhysiciansLastName;

                    this.setState({
                        formFields,
                        selectedProvider
                    }, () => this.deleteDicomPatient());

                }
                else if (e.target.tagName === 'I' && e.target.id === 'openChart') {
                    getPatientInformationByIDNew(row.patientGUID).then(data => {
                        this.setState({
                            selectedPatient: data.patientInformation
                        });

                        this.props.history.push({
                            pathname: '/patient-dashboard',
                            state: { selectedObject: data.patientInformation, examInfo: data.examInfo, filteredCategoryList: data.categoryList, patientEthnicity: data.patientEthnicity }
                        });
                    });
                }
                else {
                    this.handleBtnClick();

                    let formFields = row;
                    
                    formFields.providerID = providerID;
                    formFields.name = row.referringPhysiciansFirstName + ' ' + row.referringPhysiciansLastName;

                    this.setState({
                        formFields,
                        selectedProvider
                    });
                } 
            }
        };

        const patientColumns = [{
            dataField: 'patientModel.mrn',
            text: 'MRN #'
        }, {
            dataField: 'patientModel.lName',
            text: 'Last Name'
        }, {
            dataField: 'patientModel.fName',
            text: 'First Name'
        }, {
            dataField: 'patientModel.dob',
            text: 'DOB',
            formatter: cell => Moment(new Date(cell)).format('L')
        },  {
            isDummyField: true,
            text: 'Add to MWL',
            formatter: mwlFormatter,
            formatExtraData: {
                st: this.state
            }
        }];

        const selectPatientRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            style: { background: 'pink' },
            selected: [this.state.selectedPatient && this.state.selectedPatient.patientModel && this.state.selectedPatient.patientModel.patientID]
        };

        const rowPatientEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.tagName === 'TD' || e.target.tagName === 'TR' || e.target.tagName === 'I') {
                    this.setState({
                        selectedPatient: row
                    });
                }
            }
        };


        const expandRow = {
            renderer: row => (
                <div className="row">
                    <div className="form-group col-lg-2">
                        <label className="form-label">Study Date</label>
                        <DatePicker
                            showTimeSelect
                            className="form-control-custom"
                            onChange={(date) => this.handleTableDateChange('studyDate', date)}
                            selected={this.state.formFields.studyDate ? Moment(this.state.formFields.studyDate).toDate() : null}
                            dateFormat="MM/dd/yyyy hh:mm aa"
                            timeIntervals={15}
                            customInput={
                                <NumberFormat format="##/##/####" mask="_" />
                            }
                        />
                    </div>
                    <div className="form-group col-lg-2">
                        <label className="form-label">Last Name</label>
                        <input type="text" name="patientsLastName" value={this.state.formFields.patientsLastName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                    </div>

                    <div className="form-group col-lg-2">
                        <label className="form-label">First Name</label>
                        <input type="text" name="patientsFirstName" value={this.state.formFields.patientsFirstName || ''} onChange={this.handleInputChange} className="form-control-custom" />
                    </div>

                    <div className="form-group col-lg-2">
                        <label className="form-label">Patient ID</label>
                        <input type="text" name="patientID" value={this.state.formFields.patientID || ''} onChange={this.handleInputChange} className="form-control-custom" />
                    </div>

                    <div className="form-group col-lg-2">
                        <label className="form-label">DOB</label>
                        <DatePicker
                            selected={this.state.formFields.patientsBirthDate ? Moment(this.state.formFields.patientsBirthDate).toDate() : null}
                            onChange={(date) => this.handleTableDateChange('patientsBirthDate', date)}
                            className="form-control-custom"
                            customInput={
                                <NumberFormat format="##/##/####" mask="_" />
                            }
                        />
                    </div>

                    <div className="form-group col-lg-2">
                        <label className="form-label">Insurance</label>
                        <select className="form-control-custom" value={this.state.formFields.insurance || ''} name="insurance" onChange={this.handleInputChange}>
                            <option />
                            {this.state.insuranceList.map((ins, idx) => <option key={ins.insCompName} value={ins.insCompName} onChange={this.handleInputChange}>{ins.insCompName}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-2">
                        <label className="form-label">Referring MD</label>
                        <select className="form-control-custom" value={this.state.formFields.providerID || ''} name="providerID" onChange={this.handleInputChange}>
                            <option />
                            {this.state.providersList.map((prov, idx) => <option key={prov.providerID} value={prov.providerID} onChange={this.handleInputChange}>{prov.fName} {prov.lName}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-12">
                        <button className="btn btn-submit" onClick={this.updateDicomPatient}>Update Entry</button>
                        <button className="btn btn-outline-default margin-left-15" onClick={() => this.setState({ expanded: [] })}>Cancel</button>
                        <button className="btn btn-danger float-right" onClick={this.deleteDicomPatient}>Delete from MWL</button>
                        
                    </div>

                </div>
            ),
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            onlyOneExpanding: true,
            parentClassName: 'background-pink',
            className: 'background-pink'
        };

        return (
            <div className="">
                <div className="secondary-header">
                    {this.state.userAssociatedFacilities?.length === 1 ?
                        <div className="form-group col-lg-3">
                            <label className="form-label">Facility</label>
                            <div>{this.state.selectedFacility.facilityName}</div>
                        </div>
                        :
                        <div className="form-group col-lg-3">
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={this.handleFacilityChange}
                                className="form-control-custom w-100"
                                bordered={false}
                                value={this.state.selectedFacilityID}
                                showSearch
                                virtual={false}
                                filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                            >
                                {this.state.userAssociatedFacilities?.filter(item => item.facilityModel.inactiveYN !== 1).map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                            </Select>
                        </div>
                    }


                </div>

                

                <div className="panel-hdr pt-6">
                    <h2>
                        Worklists {/*<button onClick={() => sendText()}>Send Text</button>*/}
                    </h2>
                </div>
                
                {this.state.facilitySelected ?
                    <Fragment>
                        <div className="col-md-6 form-group">

                            <form onSubmit={this.handleSbmt}>
                                <label className="form-label">Add Patient</label>
                                <input type="text" name="searchField" value={this.state.formFields.searchField || ''} onChange={this.handleInputChange} className="form-control-custom" placeholder="Search for a patient name or MRN"/>

                                <button className="btn btn-submit mt-3" onClick={this.handleSbmt}>Search Patients</button>
                            </form>
                        </div>


                        <hr /> 
                        <div className="row">
                            <div className="form-group col-lg-4">
                                <label className="form-label">Filter By</label>
                                <DatePicker
                                    onChange={(date) => this.handleNewDateChange('startDate', date)}
                                    selectsStart
                                    startDate={this.state.selectedDates.startDate}
                                    endDate={this.state.selectedDates.endDate}
                                    className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    placeholderText='Start Date'
                                    selected={this.state.selectedDates.startDate && Moment(this.state.selectedDates.startDate).toDate()}
                                    customInput={
                                        <NumberFormat format="##/##/####" mask="_" />
                                    }
                                />

                                <DatePicker
                                    onChange={(date) => this.handleNewDateChange('endDate', date)}
                                    selectsEnd
                                    showMonthDropdown
                                    showYearDropdown
                                    startDate={this.state.selectedDates.startDate}
                                    endDate={this.state.selectedDates.endDate}
                                    className="form-control-custom mt-4"
                                    placeholderText='End Date'
                                    selected={this.state.selectedDates.endDate && Moment(this.state.selectedDates.endDate).toDate()}
                                    customInput={
                                        <NumberFormat format="##/##/####" mask="_" />
                                    }
                                />

                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Exam Category</label>

                                <Select
                                    mode="multiple"
                                    maxTagCount={1}
                                    onChange={(selectedCategories) => this.setState({ selectedCategories })}
                                    allowClear
                                    placeholder="Please select 1 or more"
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={this.state.selectedCategories}
                                >
                                    <Option value='Screening Mammo'>Screening Mammogram</Option>
                                    <Option value='Dx Mammo'>Diagnostic Mammogram</Option>
                                    <Option value='US'>Screening Ultrasound</Option>
                                    <Option value='Ultrasound'>Diagnostic Ultrasound</Option>
                                </Select>

                            </div>

                            <div className="col-12">
                                <button className="btn btn-submit mt-3" onClick={this.filterByDateRange}>Filter</button>
                            </div>
                        </div>
                        <div className="col-12">
                            <hr />
                            {this.state.filteredList.length > 0 &&
                                <BootstrapTable
                                    keyField='serNo'
                                    data={this.state.filteredList}
                                    columns={columns}
                                    pagination={paginationFactory(pagination)}
                                    rowEvents={rowEvents}
                                    expandRow={expandRow}
                                    className="worklist-table"
                                    condensed />
                            }
                        </div>
                    </Fragment>
                    :
                    <h2 className="ml-3 mt-3">Please select a facility first</h2>
                }

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='pinkCard'
                    isOpen={this.state.showPane}
                    title='Exam Details'
                    onRequestClose={() => this.setState({ showPane: false })}
                >
                    <div className="slide-pane-body scrollableDiv ">
                        <div className="row">
                            {this.state.loadingPatients ?
                                <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                :

                                <div className="col-12">
                                    <h4>Search Results</h4>
                                    <hr />
                                    {this.state.patients.length > 0 &&
                                        <BootstrapTable
                                            keyField='mrn'
                                            className="table-custom-hover"
                                            data={this.state.patients}
                                            columns={patientColumns}
                                            pagination={paginationFactory(pagination)}
                                            rowEvents={rowPatientEvents}
                                            selectRow={selectPatientRow}
                                            hover condensed />
                                    }

                                </div>
                            }
                        </div>
                    </div>

                    
                </SlidingPane>
            </div>
        );
    }
}

export default withRouter(WorklistHome);