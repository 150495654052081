import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import $ from 'jquery';
//import ribbonLogo from '../../img/ribbon_logo.png';
import ribbonLogo from '../../img/onsite_womens_health_logo.png';

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import './navigation.css';

import { confirm } from '@rickylandino/react-messages';

import ExamSearch from '../Exams/ExamSearch';

import PatientSearchForm from '../Patient/PatientSearchForm';
import OpenExamsReportModal from '../Report/Static/OpenExamsReportModal';
import TechDetailsReportModal from '../Report/Static/TechDetailsReportModal';
import RadDetailsReportModal from '../Report/Static/RadDetailsReportModal';
import PathologyReportModal from '../Report/Static/PathologyReportModal';
import ImportPatients from '../Patient/ImportPatients';
import ViewDeletedPatients from '../Admin/ViewDeletedPatients';
import { activeRoute } from '../../common/services/SpaService';
import { takeWhile } from 'rxjs/operators';

import { Switch } from 'antd';
import ManageUser from '../Admin/ManageUser';
import { addLogoutAuditItem, patientResults, updateDarkMode } from '../../common/services/ApiService';
import ResultLetterReportModal from '../Report/Static/ResultLetterReportModal';
import ImportPatientExams from '../Exams/ImportPatientExams';
import ImportPatientResults from '../Exams/ImportPatientResults';
import ImportExamResults from '../Exams/ImportExamResults';
import ImportPatientRecall from '../Patient/ImportPatientRecall';

class TopNav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                lName: '',
                fName: '',
                dob: '',
                mrn: '',
                referringMD: '',
                startDate: null,
                endDate: null,
                facilityID: Globals.selectedFacility.facilityID,
                showImportPatientsPane: false,
                showAddUserPane: false
            },
            facilitySelected: false,
            showDropdown: false,
            showExamSearch: false,
            recallTypesList: [],
            facilityList: [],
            selectedFacilities: Globals.associatedFacilities,
            showOpenExamsReportModal: false,
            showTechDetailsReportModal: false,
            showRadDetailsReportModal: false,
            showPathologyReportModal: false,
            showResultLetterPrintModal: false,
            showImportPatientExamsPane: false,
            showImportPatientResultsPane: false,
            showImportExamResultsPane: false,
            theme: window.sessionStorage.getItem("theme") || "light"
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.handleLogout = this.handleLogout.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.alive = true;

        document.addEventListener('mousedown', this.handleClickOutside);

        this.getFacilities();
        this.getRecallTypes();

        activeRoute.pipe(takeWhile(() => this.alive)).subscribe((route) => {
            switch (route) {
                case '/worklist':
                    $(".header-icon").removeClass("active");
                    $("#worklist").addClass("active");
                    break;
                case '/patients':
                    $(".header-icon").removeClass("active");
                    $("#patientSearch").addClass("active");
                    break;
                case '/recall':
                    $(".header-icon").removeClass("active");
                    $("#recall").addClass("active");
                    break;
                case '/daily-exams':
                    $(".header-icon").removeClass("active");
                    $("#dailyExamsBtn").addClass("active");
                    break;
                case '/report-definitions':
                    $(".header-icon").removeClass("active");
                    $("#settingsBtn").addClass("active");
                    break;
                default:
                    $(".header-icon").removeClass("active");
                    break;
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.state.showDropdown && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.toggleDropdown();
        }
    }

    toggleDropdown = () => {
        if (this.state.showDropdown) {
            this.setState({
                showDropdown: false,
                formFields: {
                    lName: '',
                    fName: '',
                    dob: '',
                    mrn: '',
                    referringMD: '',
                    startDate: null,
                    endDate: null,
                    facilityID: null
                }
            });
        } else {
            this.setState({ showDropdown: true });
        }
    }

    getRecallTypes = () => {
        Axios.get(`/api/GetAllRecallTypes`
        ).then(response => {
            let recallTypesList = response.data;
            recallTypesList.unshift(null);
            this.setState({
                recallTypesList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getFacilities = () => {
        Axios.get(`/api/GetAllFacilities`
        ).then(response => {
            let facilityList = response.data;

            this.setState({
                facilityList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    handleLogout(event) {
        event.persist();

        confirm({
            title: "You are about to logout",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                event.preventDefault();

                let path = '';
                path = '/';

                addLogoutAuditItem().then(data => {
                    window.sessionStorage.removeItem("userInfo");
                    Globals.isDirtyPage = false;
                    Globals.userInfo = {};
                    Globals.selectedFacility = {};

                    this.props.history.replace(path);
                }).catch(error => {
                    window.sessionStorage.removeItem("userInfo");
                    Globals.isDirtyPage = false;
                    Globals.userInfo = {};
                    Globals.selectedFacility = {};

                    this.props.history.replace(path);
                });





                return 0;
            } else {
                return 0;
            }
        });
    }

    handleNavigation(event) {
        event.preventDefault();
        let path = '';
        switch (event.target.id) {
            case 'patientSearch':
                Globals.patients = [];
                Globals.searchDirty = false;

                patientResults.next([]);
                this.props.history.replace({
                    pathname: '/patients',
                    state: { patients: [] }
                });
                break;
            case 'examSearch':
                this.setState({
                    showExamSearch: true
                });
                break;
            case 'recall':
                path = '/recall';
                this.props.history.replace(path);
                break;
            case 'admin':
                path = '/admin-home';
                this.props.history.replace(path);
                break;
            case 'worklist':
                path = '/worklist';
                this.props.history.replace(path);
                break;
            case 'providers':
                $(".listHeader").removeClass("active open");
                $("#tab3List").toggleClass("active open");
                path = '/providers';
                this.props.history.replace(path);
                break;
            case 'reporttemplates':
                path = '/reporttemplates';
                this.props.history.replace(path);
                break;
            case 'reportcomponents':
                path = '/reportcomponents';
                this.props.history.replace(path);
                break;
            case 'reportdefinitions':
                path = '/reportdefinitions';
                this.props.history.replace(path);
                break;
            case 'reportlibrary':
                path = '/reportlibrary';
                this.props.history.replace(path);
                break;
            case 'reportDefinitionBtn':
                path = '/report-definitions';
                this.props.history.replace(path);
                break;
            case 'adminPanelBtn':
                path = '/admin-panel';
                this.props.history.replace(path);
                break;
            case 'auditHistoryBtn':
                path = '/audit-history';
                this.props.history.replace(path);
                break;
            case 'openReportsBtn':
                this.setState({
                    showOpenExamsReportModal: true
                });
                break;
            case 'techDetailsBtn':
                this.setState({
                    showTechDetailsReportModal: true
                });
                break;
            case 'radDetailsBtn':
                this.setState({
                    showRadDetailsReportModal: true
                });
                break;
            case 'pathologyReportBtn':
                this.setState({
                    showPathologyReportModal: true
                });
                break;
            case 'resultLetterPrintReportBtn':
                this.setState({
                    showResultLetterPrintModal: true
                });
                break;
            case 'dailyExamsBtn':
                path = '/daily-exams';
                this.props.history.replace(path);
                break;
            case 'importPatientsBtn':
                this.setState({
                    showImportPatientsPane: true
                });
                break;
            case 'importPatientExamsBtn':
                this.setState({
                    showImportPatientExamsPane: true
                });
                break;
            case 'importPatientResultsBtn':
                this.setState({
                    showImportPatientResultsPane: true
                });
                break;
            case 'importExamResultsBtn':
                this.setState({
                    showImportExamResultsPane: true
                });
                break;
            case 'importPatientRecallBtn':
                this.setState({
                    showPatientRecallPane: true
                });
                break;
            case 'deletedPatientsBtn':
                this.setState({
                    showDeletedPatientsPane: true
                });
                break;
            case 'addUserBtn':
                this.setState({
                    showAddUserPane: true
                });
                break;
            case 'insuranceBtn':
                path = '/insurance-maintenance';
                this.props.history.replace(path);
                break;
            case 'whatsNewBtn':
                path = "/what's-new";
                this.props.history.replace(path);
                break;
            default:
                break;
        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'facilityID') {
            this.setState({
                facilitySelected: true
            });

            Globals.selectedFacility = Globals.associatedFacilities.find(fac => fac.facilityModel.facilityID === value).facilityModel;
            this.props.changeFacility();
        }

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleDropdownChange = (value) => {
        this.setState({
            value
        });
    }

    toggleDarkMode = () => {
        if (this.state.theme === "light") {
            this.setState({
                theme: "dark"
            });

            Globals.theme = "dark";
        } else {
            this.setState({
                theme: "light"
            });

            Globals.theme = "light";
        }

        window.sessionStorage.setItem("theme", Globals.theme);

        this.props.toggleDarkMode(Globals.theme);

        let user = {
            userID: JSON.parse(window.sessionStorage.getItem("userInfo"))?.userId,
            darkModeEnabled: Globals.theme === 'dark' ? true : false
        };

        updateDarkMode(user);
    }

    //downloadhelpmanual = (name) => {
    //    Axios.post(`/api/DownloadHelpManual`
    //    ).then(response => {

    //        const url = window.URL.createObjectURL(new Blob([response.data]));
    //        const a = document.createElement('a');
    //        a.style.display = 'none';
    //        a.href = url;
    //        // the filename you want
    //        a.download = name;
    //        a.click();
    //        window.URL.revokeObjectURL(url);

    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    render() {
        function downloadHelpManual(documentID) {
            const q = Axios.defaults.baseURL + "api/PinkHelpManual";

            //console.log(documentID);

            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = q;
            form.style.display = "none";

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "documentID";
            input.value = documentID;
            form.appendChild(input);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }

        const isAdmin = JSON.parse(window.sessionStorage.getItem("userInfo"))?.isAdmin;
        const isManager = JSON.parse(window.sessionStorage.getItem("userInfo"))?.managerYN;

        return (
            <header className="page-header" role="banner">
                {/* we need this logo when user switches to nav-function-top */}
                <div className="page-logo">
                    <img src={ribbonLogo} alt="Pink Logo" className="mx-auto" />
                </div>

                <div className=" d-flex mr-auto">
                    {/* activate app search icon (mobile) */}
                    <div className="hidden-sm-up">
                        <span className="header-icon mimicA" data-action="toggle" data-class="mobile-search-on" data-focus="search-field" title="Search">
                            <i className="fal fa-search" />
                        </span>
                    </div>

                    <span className="dropbtn header-icon mimicA top-nav-item" title="Worklist" id="worklist" onClick={this.handleNavigation}>
                        <i className="fas fa-th-list fa-1x text-icon" id="worklist" onClick={this.handleNavigation} /> <span className="top-nav-item-desc" id="worklist" onClick={this.handleNavigation}>Worklist</span>
                    </span>

                    <div className="dropdown">
                        <span className="dropbtn header-icon mimicA top-nav-item" id="patientSearch" onClick={this.handleNavigation} title="Patient">
                            <i className="far fa-user fa-1x text-icon" id="patientSearch" onClick={this.handleNavigation} /> <span className="top-nav-item-desc" id="patientSearch" onClick={this.handleNavigation}>Patient</span>
                        </span>
                        <div className="dropdown-content">
                            <li id="patientSearch" onClick={this.handleNavigation}>
                                <span id="patientSearch" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                    Patient Search
                                </span>
                            </li>
                            <li id="examSearch" onClick={this.handleNavigation}>
                                <span id="examSearch" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                    Exam Search
                                    </span>
                            </li>

                        </div>
                    </div>

                    <span className="dropbtn header-icon mimicA top-nav-item" title="Recall" id="recall" onClick={this.handleNavigation}>
                        <i className="fas fa-ticket-alt fa-1x text-icon" id="recall" onClick={this.handleNavigation} /> <span id="recall" onClick={this.handleNavigation} className="top-nav-item-desc">Recall</span>
                    </span>

                    <span className="dropbtn header-icon mimicA top-nav-item" title="Daily Exams" id="dailyExamsBtn" onClick={this.handleNavigation}>
                        <i className="fas fa-diagnoses fa-1x text-icon" id="dailyExamsBtn" onClick={this.handleNavigation} /> <span className="top-nav-item-desc" id="dailyExamsBtn" onClick={this.handleNavigation}>Daily Exams</span>
                    </span>

                    <div className="dropdown">
                        <span className="dropbtn header-icon mimicA top-nav-item" title="Reports" id="reports">
                            <i className="far fa-chart-bar fa-1x text-icon" /> <span className="top-nav-item-desc">Reports</span>
                        </span>
                        <div className="dropdown-content" style={{ right: '0' }}>

                            <li id="openReportsBtn" onClick={this.handleNavigation}>
                                <span className="subTabs waves-effect waves-themed" id="openReportsBtn" onClick={this.handleNavigation}>
                                    Open Exams
                                        </span>
                            </li>
                            <li id="techDetailsBtn" onClick={this.handleNavigation}>
                                <span id="techDetailsBtn" onClick={this.handleNavigation} className="subTabs waves-effect waves-themed" >
                                    Tech Summary Report
                                        </span>
                            </li>
                            <li id="radDetailsBtn" onClick={this.handleNavigation}>
                                <span id="radDetailsBtn" onClick={this.handleNavigation} className="subTabs waves-effect waves-themed" >
                                    BIRADS Report
                                        </span>
                            </li>
                            <li id="pathologyReportBtn" onClick={this.handleNavigation}>
                                <span id="pathologyReportBtn" onClick={this.handleNavigation} className="subTabs waves-effect waves-themed" >
                                    Pathology Report
                                        </span>
                            </li>
                            <li id="resultLetterPrintReportBtn" onClick={this.handleNavigation}>
                                <span id="resultLetterPrintReportBtn" onClick={this.handleNavigation} className="subTabs waves-effect waves-themed" >
                                    Result Letter Not Printed
                                        </span>
                            </li>



                            {/*
                            <div className="separator"><i className="far fa-wrench fa-1x text-icon" />&nbsp;Report Definition Tools</div>

                            <li>
                                <span id="reporttemplates" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                    Report Templates
                                </span>
                            </li>
                            <li>
                                <span id="reportcomponents" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                    Report Components
                                </span>
                            </li>
                            <li>
                                <span id="reportdefinitions" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                    Report Definitions
                                </span>
                            </li>
                            <li>
                                <span id="reportlibrary" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                    Report Library
                                </span>
                            </li>
                            */}

                        </div>
                    </div>

                </div>

                <Fragment>
                    <PatientSearchForm className="float-right" {...this.props} from='nav' />


                    <span className="dropbtn header-icon " id="patientSearch" onClick={this.handleNavigation}>
                        <span className="">OR</span>
                    </span>
                    <div className="d-flex">
                        <span className="dropbtn header-icon mimicA top-nav-item" id="examSearch" onClick={this.handleNavigation} title="Exam Search">
                            <i className="fas fa-search fa-1x text-icon" /> <span className="top-nav-item-desc" id="examSearch" onClick={this.handleNavigation}>Exam Search</span>
                        </span>
                        <div className="seperator">
                        </div>
                    </div>
                </Fragment>

                <div className="dropdown">
                    <span className="header-icon top-nav-item text-icon" id="settingsBtn" onClick={this.handleClick}>
                        <i id="settingsBtn" className="fas fa-cog text-icon" />
                    </span>

                    <div className="dropdown-content" style={{ right: '0' }}>
                        {isAdmin &&
                            <Fragment>
                                <div className="separator"><i className="fas fa-shield-alt fa-1x text-icon" /> &nbsp; Administration</div>
                                <li id="adminPanelBtn" onClick={this.handleNavigation}>
                                    <span id="adminPanelBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                        Admin Panel
                                    </span>
                                </li>



                                <li id="providers" onClick={this.handleNavigation}>
                                    <span id="providers" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                        Providers
                                                </span>
                                </li>

                                <li id="addUserBtn" onClick={this.handleNavigation}>
                                    <span id="addUserBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                        Add User
                                        </span>
                                </li>

                                {/*<div className="separator"><i className="fas fa-upload fa-1x text-icon" /> &nbsp; Imports</div>*/}

                                {/*<li id="importPatientsBtn" onClick={this.handleNavigation}>*/}
                                {/*    <span id="importPatientsBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>*/}
                                {/*        Import Patients*/}
                                {/*        </span>*/}
                                {/*</li>*/}
                                {/*<li id="importPatientExamsBtn" onClick={this.handleNavigation}>*/}
                                {/*    <span id="importPatientExamsBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>*/}
                                {/*        Import Patient Exams*/}
                                {/*        </span>*/}
                                {/*</li>*/}
                                {/*<li id="importPatientResultsBtn" onClick={this.handleNavigation}>*/}
                                {/*    <span id="importPatientResultsBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>*/}
                                {/*        Import Patient Results*/}
                                {/*        </span>*/}
                                {/*</li>*/}
                                {/*<li id="importExamResultsBtn" onClick={this.handleNavigation}>*/}
                                {/*    <span id="importExamResultsBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>*/}
                                {/*        Import/Map Exam Results*/}
                                {/*        </span>*/}
                                {/*</li>*/}
                                {/*<li id="importPatientRecallBtn" onClick={this.handleNavigation}>*/}
                                {/*    <span id="importPatientRecallBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>*/}
                                {/*        Import Patient Recall*/}
                                {/*        </span>*/}
                                {/*</li>*/}
                            </Fragment>
                        }

                        {(isManager || isAdmin) &&
                            <>
                                <div className="separator"><i className="fas fa-wrench fa-1x text-icon" /> &nbsp; Management</div>

                                <li id="deletedPatientsBtn" onClick={this.handleNavigation}>
                                    <span id="deletedPatientsBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                        View Deleted Patients
                                        </span>
                                </li>
                                <li id="auditHistoryBtn" onClick={this.handleNavigation}>
                                    <span id="auditHistoryBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                        View Audit History
                                        </span>
                                </li>
                            </>
                        }
                        <div className="separator"><i className="fas fa-wrench fa-1x text-icon" /> &nbsp; Maintenance</div>
                        <li id="insuranceBtn" onClick={this.handleNavigation}>
                            <span id="insuranceBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                Insurance Companies
                                            </span>
                        </li>

                        {isAdmin &&
                            <Fragment>
                                <div className="separator"><i className="fas fa-wrench fa-1x text-icon" />&nbsp;Report Definition Tools</div>
                                <li>
                                    <span id="reportDefinitionBtn" className="subTabs waves-effect waves-themed" onClick={this.handleNavigation}>
                                        Pink Report Builder
                                        </span>
                                </li>
                            </Fragment>
                        }
                        <div className="separator"><i className="fas fa-adjust fa-1x text-icon" /> &nbsp; Dark Mode</div>
                        <li>
                            <Switch checked={this.state.theme === "light" ? false : true} onChange={this.toggleDarkMode} />
                        </li>
                    </div>
                </div>

                <div className="dropdown">
                    <span className="header-icon top-nav-item text-icon" title="Help" id="help">
                        <i className="fas fa-question-circle text-icon" />
                    </span>
                    <div className="dropdown-content" style={{ right: '0' }}>
                        <li id="openHelpBtn" onClick={() => downloadHelpManual('Pink Help Manual')}>
                            {/*<button className="btn btn-outline-default margin-left-15" onClick={() => handlePrintHx(this.state.patient.patientID)}>Print Hx Sheet</button>*/}
                            <span onClick={() => downloadHelpManual('Pink Help Manual')} className="subTabs waves-effect waves-themed" id="openHelpBtn">
                                <div id="openHelpBtn" onClick={this.handleNavigation}>PINK User Manual</div>
                            </span>
                        </li>

                        <li id="whatsNewBtn" onClick={this.handleNavigation}>
                            {/*<button className="btn btn-outline-default margin-left-15" onClick={() => handlePrintHx(this.state.patient.patientID)}>Print Hx Sheet</button>*/}
                            <span onClick={this.handleNavigation} className="subTabs waves-effect waves-themed" id="whatsNewBtn">
                                <div id="whatsNewBtn" onClick={this.handleNavigation}>What's New?</div>
                            </span>
                        </li>
                    </div>
                </div>

                {/* app notification */}
                {/*<div className="float-right">
                    <span href="#" className="header-icon top-nav-item text-icon" data-toggle="dropdown" title="You got 11 notifications">
                        <i className="fal fa-bell text-icon" />
                        <span className="badge badge-icon ">11</span>
                    </span>
                    <div className="dropdown-menu dropdown-menu-animated dropdown-xl">
                        <div className="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top mb-2">
                            <h4 className="m-0 text-center color-white">
                                11 New
                          <small className="mb-0 opacity-80">User Notifications</small>
                            </h4>
                        </div>
                        <ul className="nav nav-tabs nav-tabs-clean" role="tablist">
                            <li className="nav-item">
                                <span className="nav-link px-4 fs-md js-waves-on fw-500" data-toggle="tab" href="#tab-messages" data-i18n="drpdwn.messages">Messages</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link px-4 fs-md js-waves-on fw-500" data-toggle="tab" href="#tab-feeds" data-i18n="drpdwn.feeds">Feeds</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link px-4 fs-md js-waves-on fw-500" data-toggle="tab" href="#tab-events" data-i18n="drpdwn.events">Events</span>
                            </li>
                        </ul>
                        <div className="tab-content tab-notification">
                            <div className="tab-pane active p-3 text-center">
                                <h5 className="mt-4 pt-4 fw-500">
                                    <span className="d-block fa-3x pb-4 text-muted">
                                        <i className="ni ni-arrow-up text-gradient opacity-70" />
                                    </span> Select a tab above to activate
                            <small className="mt-3 fs-b fw-400 text-muted">
                                        This blank page message helps protect your privacy, or you can show the first message here automatically through
                              <span href="#">settings page</span>
                                    </small>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>*/}

                <div>
                    <span className="header-icon mimicA top-nav-item" onClick={this.handleLogout} title={'Logout, ' + Globals.userInfo.fullName}>
                        <i className="fas fa-sign-out-alt logoutIcon text-icon"></i>
                    </span>
                </div>


                {/* DOC: nav menu layout change shortcut */}
                <div className="hidden-md-down dropdown-icon-menu position-relative">
                    <span className="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden" title="Hide Navigation">
                        <i className="ni ni-menu" />
                    </span>
                    <ul>
                        <li>
                            <span className="btn js-waves-off" data-action="toggle" data-class="nav-function-minify" title="Minify Navigation">
                                <i className="ni ni-minify-nav" />
                            </span>
                        </li>
                        <li>
                            <span className="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed" title="Lock Navigation">
                                <i className="ni ni-lock-nav" />
                            </span>
                        </li>
                    </ul>
                </div>
                {/* DOC: mobile button appears during mobile width */}
                <div className="hidden-lg-up">
                    <span onClick={this.props.showLeftNav} className="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
                        <i className="ni ni-menu" />
                    </span>
                </div>

                {this.state.showExamSearch && <ExamSearch showPane={this.state.showExamSearch} from='general' hidePane={() => this.setState({ showExamSearch: false })} />}
                {this.state.showOpenExamsReportModal && <OpenExamsReportModal showModal={this.state.showOpenExamsReportModal} hideModal={() => this.setState({ showOpenExamsReportModal: false })} />}
                {this.state.showTechDetailsReportModal && <TechDetailsReportModal showModal={this.state.showTechDetailsReportModal} hideModal={() => this.setState({ showTechDetailsReportModal: false })} />}
                {this.state.showRadDetailsReportModal && <RadDetailsReportModal showModal={this.state.showRadDetailsReportModal} hideModal={() => this.setState({ showRadDetailsReportModal: false })} />}
                {this.state.showPathologyReportModal && <PathologyReportModal showModal={this.state.showPathologyReportModal} hideModal={() => this.setState({ showPathologyReportModal: false })} />}
                {this.state.showResultLetterPrintModal && <ResultLetterReportModal showModal={this.state.showResultLetterPrintModal} hideModal={() => this.setState({ showResultLetterPrintModal: false })} />}

                {this.state.showImportPatientsPane && <ImportPatients showPane={this.state.showImportPatientsPane} hidePane={() => this.setState({ showImportPatientsPane: false })} />}
                {this.state.showImportPatientExamsPane && <ImportPatientExams showPane={this.state.showImportPatientExamsPane} hidePane={() => this.setState({ showImportPatientExamsPane: false })} />}
                {this.state.showImportPatientResultsPane && <ImportPatientResults showPane={this.state.showImportPatientResultsPane} hidePane={() => this.setState({ showImportPatientResultsPane: false })} />}
                {this.state.showImportExamResultsPane && <ImportExamResults showPane={this.state.showImportExamResultsPane} hidePane={() => this.setState({ showImportExamResultsPane: false })} />}
                {this.state.showPatientRecallPane && <ImportPatientRecall showPane={this.state.showPatientRecallPane} hidePane={() => this.setState({ showPatientRecallPane: false })} />}


                {this.state.showDeletedPatientsPane && <ViewDeletedPatients showPane={this.state.showDeletedPatientsPane} hidePane={() => this.setState({ showDeletedPatientsPane: false })} />}
                {this.state.showAddUserPane && <ManageUser hidePane={() => this.setState({ showAddUserPane: false })} />}
            </header>
        );
    }
}

export default withRouter(TopNav);