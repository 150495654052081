import Axios from '../../config/axios';

import { BehaviorSubject } from 'rxjs';
import Globals from '../../config/globals';

export var patientSearchCriteria = "";

export const userAssociatedFacilities = new BehaviorSubject([]);

export const patientResults = new BehaviorSubject([]);
export const loadingPatientResults = new BehaviorSubject(false);

export const allProviders = new BehaviorSubject([]);
export const allTechnologists = new BehaviorSubject([]);
export const allInsuranceCompanies = new BehaviorSubject([]);
export const allDensity = new BehaviorSubject([]);
export const allRecallTypes = new BehaviorSubject([]);
export const allRecommendations = new BehaviorSubject([]);
export const allBirads = new BehaviorSubject([]);
export const allExamReasons = new BehaviorSubject([]);
export const allBiopsyTypes = new BehaviorSubject([]);
export const allInactiveReasons = new BehaviorSubject([]);
export const allCategories = new BehaviorSubject([]);

export function getAllTypes() {
    Axios.get(`/api/GetAllTypes`).then(response => {
        allProviders.next(response.data.allProviders);
        allTechnologists.next(response.data.allTechnologists);
        allInsuranceCompanies.next(response.data.allInsuranceCompanies);
        allDensity.next(response.data.allDensity);
        allRecallTypes.next(response.data.allRecallTypes);
        allRecommendations.next(response.data.allRecommendations);
        allBirads.next(response.data.allBirads);
        allExamReasons.next(response.data.allExamReasons);
        allBiopsyTypes.next(response.data.allBiopsyTypes);
        allInactiveReasons.next(response.data.allInactiveReasons);
        allCategories.next(response.data.allCategories);
    }).catch(error => error);
}

export function setAllTypes(data) {

    allProviders.next(data.allProviders);
    allTechnologists.next(data.allTechnologists);
    allInsuranceCompanies.next(data.allInsuranceCompanies);
    allDensity.next(data.allDensity);
    allRecallTypes.next(data.allRecallTypes);
    allRecommendations.next(data.allRecommendations);
    allBirads.next(data.allBirads);
    allExamReasons.next(data.allExamReasons);
    allBiopsyTypes.next(data.allBiopsyTypes);
    allInactiveReasons.next(data.allInactiveReasons);
    allCategories.next(data.allCategories);
}

export function getPatientsByLookup(postdata) {
    return Axios.post(`/api/GetPatientsByLookup`, postdata
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export const getInsuranceCompanies = async() => {
    const response = await Axios.post(`/api/GetAllInsuranceCompanies`);
    return response.data;
}

export function getPatientInformationById(uniqueID) {
    let postdata = {
        uniqueID
    };

    return Axios.post(`/api/GetPatientInformationByID`, postdata
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getAllPatientExamInfoByPatient(uniqueID) {
    let postdata = {
        uniqueID
    };

    return Axios.get(`/api/GetAllPatientExamInfoByPatient`, {
        params: {
            patientID: uniqueID
        }
    }).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getPatientInformationByIDNew(patientID) {
    let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    return Axios.get('/api/GetPatientInformationByIDNew', {
        params: {
            patientID,
            userID: userInfo.userId
        }
    }).then(response => response.data).catch(error => console.log(error));
}

export function importPatients(file, facilityID) {
    let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('facilityID', facilityID);
    formdata.append('userID', userInfo.userId);

    return Axios.post(`/api/ImportExcel`, formdata
    ).then(response => 
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function importPatientExamsExcel(file) {
    let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('userID', userInfo.userId);

    return Axios.post(`/api/ImportPatientExams`, formdata
    ).then(response => 
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function importPatientResultsExcel(file) {
    let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('userID', userInfo.userId);

    return Axios.post(`/api/ImportPatientResults`, formdata
    ).then(response => 
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function importExamResultsExcel(file) {
    let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('userID', userInfo.userId);

    return Axios.post(`/api/ImportExamResults`, formdata
    ).then(response => 
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function importPatientRecall(file) {
    let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('userID', userInfo.userId);

    return Axios.post(`/api/ImportPatientRecall`, formdata
    ).then(response => 
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function setPatientInactive(postdata) {
    return Axios.post(`/api/SetPatientInactive`, postdata
    ).then(response => 
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function setPatientActive(postdata) {
    return Axios.post(`/api/SetPatientActive`, postdata
    ).then(response =>
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function deletePatient(postdata) {
    return Axios.post(`/api/DeletePatient`, postdata
    ).then(response =>
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function restorePatient(postdata) {
    return Axios.post(`/api/RestorePatient`, postdata
    ).then(response =>
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function getDeletedPatients() {
    return Axios.get(`/api/GetDeletedPatients`
    ).then(response =>
        response.data
    ).catch(error => {
        console.log(error);
    });
}

export function downloadPatientList(patientResults, fileName) {
    
    let postdata = {
        patients: patientResults,
        fileName,
        searchCriteria: JSON.stringify(Globals.searchCriteria)
    }
    return Axios.post(`/api/DownloadPatientList`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function exportAuditList(postdata) {
    return Axios.post(`/api/ExportAuditList`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function downloadIDNameList(providerResults, fileName, type) {

    let postdata = {
        content: providerResults,
        type,
        fileName
    }
    return Axios.post(`/api/DownloadIDNameList`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function exportErrorList(postdata) {

    return Axios.post(`/api/ExportErrorList`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function downloadEncryptedFile() {
    return Axios.get(`/api/DownloadEncryptedFile`).then(response => response.data);
}

export function hashwordConversion() {
    let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let postdata = {
        name: userInfo.username
    };

    return Axios.post(`/api/HashwordConversion`, postdata).then(response => response.data);
}

export function getAllFacilities() {
    return Axios.get('/api/GetAllFacilities').then(response => response.data);
}

export function getDocumentTypesByLevel(level) {
    return Axios.get('/api/GetDocumentTypesByLevel', {
        params: {
            level
        }
    }).then(response => response.data);
}

export function getJustCategoryByFacility(facilityID) {
    return Axios.get(`/api/GetJustCategoryByFacility`, {
        params: {
            facilityID
        }
    }).then(response => response.data);
}

export function isUsernameUnique(username) {
    return Axios.get('/api/IsUsernameUnique', {
        params: {
            username
        }
    }).then(response => response.data);
}

export function isEmailUnique(email) {
    return Axios.get('/api/IsEmailUnique', {
        params: {
            email
        }
    }).then(response => response.data);
}

export function getUserInformationByID(userID) {
    return Axios.get('/api/GetUserInformationByID', {
        params: {
            userID
        }
    }).then(response => response.data);
}

export function updateUser(user) {
    return Axios.post('/api/UpdateUser', user).then(response => response.data);
}

export function addLogoutAuditItem() {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let postdata = {
        userID: userInfo.userId
    }

    return Axios.post('/api/AddLogoutAuditItem', postdata).then(response => response.data).catch(error => error);
}

export function getVersion() {
    return Axios.get("/api/GetVersion").then(response => response.data);
}

export function getConnString() {
    return Axios.get("/api/GetConnString").then(response => response.data);
}

export function hashPW(text) {
    return Axios.get("/api/HashPW", { params: {text}}).then(response => response.data);
}

export function updateDarkMode(user) {
    return Axios.post('/api/ToggleDarkMode', user).then(response => response.data);
}

export function encryptPW(pw) {

    let postdata = {
        content: pw
    };

    return Axios.post('/api/EncryptPW', postdata).then(response => response.data);
}

export function decryptPW(pw) {

    let postdata = {
        content: pw
    };

    return Axios.post('/api/DecryptPW', postdata).then(response => response.data);
}

export function downloadOpenExamsList(postdata) {

    return Axios.post(`/api/DownloadOpenExamsList`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function downloadTechSummary(postdata) {

    return Axios.post(`/api/DownloadTechSummary`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function downloadResultLetterPrintList(postdata) {

    return Axios.post(`/api/DownloadResultLetterPrintList`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function downloadRadSummary(postdata) {

    return Axios.post(`/api/DownloadRadSummary`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function downloadPathologyDetails(postdata) {

    return Axios.post(`/api/DownloadPathologyDetails`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function resetTFA(postdata) {
    return Axios.post('/api/ResetTFA', postdata).then(response => response.data);
}
